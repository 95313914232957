export function HotelLogo({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      enableBackground="new 0 0 100 77"
      viewBox="0 0 100 77"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fillColor}>
        <g>
          <path
            d="M57.36,18.48c-0.01-2.71-1.87-4.88-5.25-5.91L52.09,6.4l6.25-0.02l0.05,15.11l-1.95,0.01
			C57.12,20.52,57.36,19.42,57.36,18.48 M48.68,0C37.34,0.04,29.02,7.26,29.05,14.96c0.01,3.98,2.26,7.32,5.09,9.63l0.21-0.15
			c-2.24-1.84-4.27-4.73-4.28-8.7C30.63,7.32,41,2.54,50.23,2.51c6.53-0.02,18.27,1.45,18.3,12.37c0.02,5.7-6.05,13.12-18.83,13.16
			c-6.55,0.02-11.64-3.47-11.66-8.5c-0.01-4.36,3.39-7.48,8.48-7.49c1.4,0,2.72,0.32,3.72,0.59c-2.13-0.38-5.42,0.18-7.12,1.53
			l0.02,7.37l6.26-0.02l-0.03-8.38c1.18,0.21,2.1,0.65,2.73,1.04l0.02,5l0.01,2.33l1.63-0.01c-1.28,1.44-3.66,2.32-5.73,2.01l0,0.31
			c5.95,0.45,8.46-2.38,8.45-5.55c-0.01-3.73-3.52-5.91-7.1-6.35L49.37,6.4l-6.26,0.02L43.13,12c-4.05,0.83-8,3.25-7.99,7.23
			c0.02,6.13,8.61,9.91,16.07,9.88c11.34-0.04,20.05-5.54,20.02-13.23C71.2,6.57,58.58-0.03,48.68,0"
          />
        </g>
        <g>
          <path
            d="M68.47,27.52c0-0.77,0.62-1.4,1.4-1.4c0.77,0,1.4,0.62,1.4,1.39c0,0.78-0.62,1.4-1.39,1.41
			C69.09,28.93,68.47,28.3,68.47,27.52 M71.02,27.52c0-0.63-0.52-1.15-1.15-1.15c-0.64,0-1.16,0.52-1.15,1.15
			c0,0.64,0.52,1.16,1.16,1.15C70.51,28.67,71.02,28.16,71.02,27.52 M69.57,27.61l0,0.71l-0.25,0l-0.01-1.64l0.53,0
			c0.3,0,0.61,0.08,0.62,0.45c0,0.19-0.12,0.34-0.34,0.38l0,0.01c0.23,0.04,0.26,0.14,0.28,0.32c0.03,0.16,0.04,0.33,0.1,0.48
			l-0.33,0c-0.02-0.09-0.04-0.2-0.06-0.29c-0.02-0.14-0.02-0.27-0.09-0.35c-0.06-0.07-0.15-0.06-0.23-0.07L69.57,27.61L69.57,27.61z
			 M69.84,27.36c0.24-0.01,0.3-0.11,0.3-0.24c0-0.12-0.06-0.19-0.26-0.19l-0.31,0l0,0.42L69.84,27.36L69.84,27.36z"
          />
        </g>
        <g>
          <polygon
            points="21.85,37.73 21.92,59.22 23.59,59.44 23.59,60.7 15.34,60.72 15.33,59.46 17.11,59.24 17.08,49.46 
			6.52,49.49 6.55,59.27 8.18,59.49 8.19,60.75 0.08,60.77 0.07,59.51 1.7,59.28 1.63,37.8 0,37.58 0,36.36 8.11,36.33 8.11,37.55 
			6.49,37.78 6.51,46.97 17.07,46.94 17.04,37.75 15.26,37.53 15.26,36.31 23.52,36.28 23.52,37.51 21.85,37.73 		"
          />
        </g>
        <g>
          <path
            d="M32.88,59.33l1.56,0.22l0,1.11l-7.59,0.02l0-1.11l1.56-0.23l-0.05-14.71l-1.56-0.22l0-1.11l6.04-0.02
			L32.88,59.33L32.88,59.33z M33.36,37.81c0.01,1.63-1.1,2.86-2.62,2.86c-1.56,0-2.63-1.1-2.64-2.73c0-1.56,1.06-2.78,2.62-2.79
			C32.24,35.14,33.36,36.36,33.36,37.81L33.36,37.81z"
          />
        </g>
        <g>
          <polygon
            points="37.73,59.54 39.25,59.31 39.18,36.83 37.66,36.61 37.65,35.5 43.69,35.48 43.77,59.3 45.32,59.52 
			45.33,60.63 37.73,60.65 37.73,59.54 		"
          />
        </g>
        <g>
          <path
            d="M54.49,43.23l4.07-0.01l0.01,1.63l-4.07,0.01l0.03,10.34c0.01,2.67,0.57,3.52,2.97,3.51
			c0.89,0,1.67-0.01,2.41-0.19l0,1.19c-2.18,0.78-4.03,1.12-5.59,1.13c-3.48,0.01-4.3-1.58-4.31-4.62l-0.04-11.33l-3.18,0.01
			l-0.01-1.63l3.3-0.01l-0.01-3.63l4.4-1.35L54.49,43.23L54.49,43.23z"
          />
        </g>
        <g>
          <path
            d="M78.92,52c0.02,5.41-3.6,9.05-9.3,9.07c-5.52,0.02-9.04-2.34-9.07-9.01c-0.02-5.59,3.6-9.35,9.3-9.37
			C75.48,42.68,78.9,46.04,78.92,52L78.92,52z M73.95,51.91c-0.02-5.48-1.65-7.89-4.28-7.88c-2.55,0.01-4.18,2.31-4.16,7.91
			c0.02,5.52,1.65,7.81,4.21,7.8C72.46,59.73,73.97,57.54,73.95,51.91L73.95,51.91z"
          />
        </g>
        <g>
          <path
            d="M100,59.34l0,1.11l-7.59,0.02l0-1.11l1.52-0.23l-0.03-9.89c-0.01-2.74-1.01-3.96-3.09-3.96
			c-0.96,0-2.48,0.67-3.59,1.46l0.04,12.41l1.56,0.22l0,1.11l-7.63,0.02l0-1.11l1.56-0.23l-0.05-14.59l-1.56-0.22l0-1.22l6.07-0.02
			l0.01,2.41c1.88-1.53,3.69-2.75,6.25-2.76c3.41-0.01,4.93,1.99,4.94,5.91l0.03,10.44L100,59.34L100,59.34z"
          />
        </g>
        <g>
          <path
            d="M33.43,76.21c-1.81,0.01-2.95-1.27-2.96-3.2c-0.01-1.93,1.17-3.23,2.93-3.24c0.72,0,1.28,0.17,1.74,0.45
			l-0.17,1.13c-0.41-0.31-0.91-0.55-1.52-0.55c-1.1,0-1.77,0.84-1.77,2.2c0,1.37,0.73,2.18,1.83,2.18c0.58,0,1.1-0.21,1.58-0.53
			l0.17,1.03C34.77,75.99,34.14,76.21,33.43,76.21z"
          />
          <path
            d="M40.39,76.08l-0.01-2.71l-2.64,0.01l0.01,2.71l-1.2,0l-0.02-6.22l1.2,0l0.01,2.45l2.64-0.01l-0.01-2.45l1.2,0
			l0.02,6.22L40.39,76.08z"
          />
          <path d="M43.13,76.07l-0.02-6.22l1.2,0l0.02,6.22L43.13,76.07z" />
          <path
            d="M48.56,76.16c-1.81,0.01-2.95-1.27-2.96-3.2c-0.01-1.93,1.17-3.23,2.93-3.24c0.72,0,1.28,0.17,1.74,0.45
			l-0.17,1.13c-0.41-0.31-0.91-0.55-1.52-0.55c-1.1,0-1.77,0.84-1.77,2.2c0,1.37,0.73,2.18,1.83,2.18c0.58,0,1.1-0.21,1.58-0.53
			l0.17,1.03C49.9,75.94,49.27,76.16,48.56,76.16z"
          />
        </g>
        <g>
          <path
            d="M55.72,76.09l-0.6-1.64l-2.35,0.01l-0.56,1.58l-1.18,0l2.32-6.26l1.27,0l2.3,6.09L55.72,76.09z M53.92,71.09
			l-0.84,2.4l1.7-0.01L53.92,71.09z"
          />
          <path
            d="M60.51,76.13c-1.98,0.01-3.06-1.29-3.07-3.21c-0.01-1.93,1.13-3.24,3.03-3.25c0.79,0,1.4,0.19,1.9,0.49
			l-0.17,1.11c-0.47-0.33-1.03-0.58-1.72-0.58c-1.22,0-1.83,0.89-1.82,2.22c0,1.33,0.64,2.22,1.89,2.21c0.43,0,0.74-0.09,1.05-0.26
			l0-1.38l-1.26,0l-0.15-0.96l2.5-0.01l0.01,2.97C62.11,75.87,61.34,76.13,60.51,76.13z"
          />
        </g>
        <g>
          <path
            d="M66.76,76.11c-1.77,0.01-2.92-1.29-2.92-3.21c-0.01-1.93,1.14-3.24,2.92-3.25c1.79-0.01,2.92,1.29,2.92,3.21
			C69.69,74.79,68.54,76.11,66.76,76.11z M66.75,70.65c-1.06,0-1.7,0.88-1.7,2.24c0,1.36,0.65,2.23,1.71,2.23
			c1.07,0,1.71-0.88,1.71-2.24C68.47,71.52,67.81,70.64,66.75,70.65z"
          />
        </g>
      </g>
    </svg>
  );
}
